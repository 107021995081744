<template>
    <div class="quiz-container">
        <div class="quiz-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackThree">班级管理</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">小测列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{paperName}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="quiz-list">
                <el-table :data="approvedList" border style="width: 100%; flex: 1; margin-top: 20px;" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
                    <el-table-column prop="s_name" label="学生姓名"></el-table-column>
                    <el-table-column prop="score" label="成绩"></el-table-column>
                    <el-table-column prop="submit_time" label="提交时间"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_read === 1">未批阅</span>
                            <span v-else>已批阅</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="t_name" label="批阅人"></el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="toDetail(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center" background style="margin: 20px 0;"
                               :current-page.sync="quizPages.currentPageNum"
                               :page-size="quizPages.eachPageNum"
                               :total="quizPages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="quizCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                quizId: Number(this.$route.query.quizId ) || '',
                classId: Number(this.$route.query.classId ) || '',
                className: this.$route.query.className || '',
                paperName: this.$route.query.paperName || '',
                approvedList: [],
                quizPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                }
            }
        },
        mounted() {
            this.getApprovedList();
        },
        methods: {
            getApprovedList() {
                let params = {
                    quiz_id: this.quizId,
                    c_id: this.classId,
                    is_read: 0,
                    page: this.quizPages.currentPageNum,
                    limit: this.quizPages.eachPageNum,
                }
                this.$shttp.axiosGetBy(this.$api.getRecordList, params, (res) => {
                    if (res.code === 200) {
                        this.approvedList = res.data.data;
                        this.quizPages.total = res.data.total
                    }
                })
            },
            quizCurrentChange(val) {
                this.quizPages.currentPageNum = val;
                this.getApprovedList();
            },
            toDetail(row) {
                this.$router.push({
                    path: '/teacherClass/allApprovedDetail',
                    query: {
                        quizId: this.quizId,
                        studentId: row.s_id,
                        classId: this.classId,
                        className: this.className,
                        paperName: this.paperName,
                        studentName: row.s_name,
                    }
                })
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .quiz-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .quiz-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .quiz-list {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                .course-list {
                    padding: 20px;
                }
            }
        }
    }
</style>